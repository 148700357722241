/* eslint-disable */
import requestService from '@/js/common/Domain/Service/request';
import setAdditionalMetaOptions from '@/js/common/Service/setAdditionalMetaOptions';

const getApproximateMinPriceByCriteriasService = (options, callback) => {
    let prices = [];

    const { tourCriterias } = options;
    const tourCriteriaHashesForRequest = _.map(tourCriterias, (tourCriteria) => {
        return JSON.stringify(tourCriteria);
    });

    if (prices.length && callback?.success) {
        callback.success(prices);
    }

    prices = [];

    if (tourCriteriaHashesForRequest.length) {
        const requestOptions = {
            url: `${window.appConfig.apiGatewayUrl}/frontend/prices/searchMinPriceByCriterias`,
            method: 'POST',
            data: {
                tourCriterias: tourCriteriaHashesForRequest,
                ...setAdditionalMetaOptions(),
            },
        };

        requestService(requestOptions, {
            success(response) {
                if (response.success === true && response?.result?.length) {
                    prices = _.map(response.result, (criteriaPrice) => {
                        return criteriaPrice;
                    });
                }

                if (callback?.success) {
                    callback.success(prices);
                }
            },
            error() {
                if (callback?.error) {
                    callback.error();
                }
            },
        });
    }
};

export default getApproximateMinPriceByCriteriasService;
