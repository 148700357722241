import moment from 'moment';
import 'moment/locale/ru';

import PriceCalendarView from '@/js/common/components/PriceCalendar/PriceCalendar';
import CheckInDateRangeObjectValue from '@/js/common/Domain/ObjectValue/CheckInDateRange';
import tourCriteriaJsonHydrator from '@/js/common/Domain/Hydrator/tourCriteriaJson';
import setAdditionalMetaOptions from '@/js/common/Service/setAdditionalMetaOptions';
import PriceCalendarIndexModel from './PriceCalendarIndexModel';

export default class PriceCalendarIndexView extends PriceCalendarView {
    getCalendarData(params) {
        const tourCriteria = params.searchData;
        const calendarTourCriteria = tourCriteria.clone();
        let startCalendarTime;
        const offset = this.model.get('offset');

        this.model.recompute('expandedDatesObject');

        calendarTourCriteria.set('checkInDateRange', new CheckInDateRangeObjectValue(this.model.get('expandedDates')));

        const data = {
            ..._.pick(tourCriteriaJsonHydrator(calendarTourCriteria), 'checkInDateRange', 'countries', 'departureCity', 'resorts', 'hotels', 'nightRange', 'meals', 'hotelCategories', 'operators', 'touristGroup'),
            ...setAdditionalMetaOptions(),
        };

        const options = {
            url: `${window.appConfig.apiGatewayUrl}/frontend/prices/searchLatest`,
            data,
        };

        if (data.countries && data.countries.length === 1) {
            startCalendarTime = new Date().getTime();
            this.ajaxRequestManager.abortAll(options);
            this.$el.addClass('loading');

            this.ajaxRequestManager.getData(options, {
                success: (responseData, fullData) => {
                    let calendarData = fullData.result;
                    let noData = true;

                    calendarData = _.map(calendarData, (dateData) => {
                        if (!_.isNull(dateData.price) || dateData.price) {
                            noData = false;
                        }

                        return {
                            date: moment(dateData.checkindate, 'YYYY-MM-DD'),
                            price: Math.round(dateData.price),
                        };
                    });

                    this.trackCalendar({
                        tourCriteria: calendarTourCriteria,
                        calendarData,
                        startCalendarTime,

                    });

                    if (!noData || offset) {
                        this.showCalendar();
                        this.$el.removeClass('noData');
                        this.$el.removeClass('loading');
                        this.model.set('calendarData', calendarData);
                    } else {
                        this.$el.removeClass('loading');
                        this.$el.addClass('noData');
                        this.showCalendar();
                    }
                },
                error() {
                },
            });
        } else {
            this.hideCalendar();
        }
    }

    initialize(options) {
        const model = new PriceCalendarIndexModel();
        super.initialize({ ...options, model });
    }
}