/* eslint-disable */
import './testomonials.css';
import '@/js/desktop/components/testimonials/testimonials.css';

import 'jcarousel';

let TestimonialsView = Backbone.View.extend({
	initSlider:function() {
		var jcarousel = $('.testimonials-jcarousel');

		jcarousel.on('jcarousel:reload jcarousel:create', function() {
			var carousel = $(this);
			carousel.jcarousel('items').css('width', 220 + 'px');
		}).jcarousel({
			list: '.testimonials-list',
			items: '.userReview',
			wrap: 'both',
			vertical:  false
		});

		$('.testimonials .jcarousel-control-prev').jcarouselControl({
			target: '-=1'
		});

		$('.testimonials .jcarousel-control-next').jcarouselControl({
			target: '+=1'
		});

		$('.testimonials .jcarousel-pagination').on('jcarouselpagination:active', 'a', function() {
			$(this).addClass('active');
		}).on('jcarouselpagination:inactive', 'a', function() {
			$(this).removeClass('active');
		}).on('click', function(e) {
			e.preventDefault();
		});
	},
	initialize: function () {
		this.initSlider();
	}
});

export default TestimonialsView;