/* eslint-disable */
import tourCriteriaService from "../Service/tourCriteria";
import tourCriteriaJsonHydrator from "./tourCriteriaJson";

let tourCriteriasForHotMinPricesHydrator = function(options) {
	var departureCity = options.departureCity,
		tourCriterias,
		countries = options.countries,
		departureCityType = departureCity == 1 || departureCity == 2 ? 'capital' : 'region',
		countryTourCriteriaChangedFields = tourCriteriaService.getTourCriteriaChangedFields(),
		currentTourCriteria = options.currentTourCriteria,
		searchDataJson = tourCriteriaJsonHydrator(currentTourCriteria),
		searchDataJsonForCurrentCountry = _.pick(searchDataJson, 'checkInDateRange', 'nightRange', 'country'),
		searchDataChangedFieldsValues = _.pick.apply(this, [].concat(searchDataJson, countryTourCriteriaChangedFields));


	tourCriterias = countries.map(function(country){
		let resorts = (countries.length > 1 && country.get('id') === 76) ? [3097 /* Big Sochi*/, 1691 /*Krasnaya polyana*/] : [],
			tourCriteria = _.extend({}, country.get(departureCityType + 'TourCriteria'), {
				departureCity:departureCity,
				countries:[country.get('id')],
				resorts:resorts,
				meal:[],
				hotelCategory:[]
			}, searchDataChangedFieldsValues);

		if(searchDataJson.countries && searchDataJson.countries.length && searchDataJson.countries.indexOf(country.get('id')) !== -1) {
			_.extend(tourCriteria, _.omit(searchDataJsonForCurrentCountry, 'country'));
		}

		return tourCriteria;
	});

	return tourCriterias;
};

export default tourCriteriasForHotMinPricesHydrator;
