import tourCriteriaJsonHydrator from '@/js/common/Domain/Hydrator/tourCriteriaJson';
import getApproximateMinPriceByCriteriasService from '@/js/common/Domain/Service/getApproximateMinPriceByCriterias';

export default class ShortTourCriteriaCollection extends Backbone.Collection {
    initialize() {
        this.initEvents();
    }

    initEvents() {
        this.on('reset', this.collectionChanged.bind(this));
    }

    collectionChanged() {
        if (!this?.length) {
            return;
        }

        const tourCriterias = this.map((item, index) => {
            const tourCriteria = item.get('tourCriteria');
            const preparedTourCriteria = tourCriteriaJsonHydrator(tourCriteria);
            preparedTourCriteria.index = index;

            return preparedTourCriteria;
        });

        getApproximateMinPriceByCriteriasService({ tourCriterias }, {
            success: (data) => {
                _.each(data, (item) => {
                    const currentCriteria = JSON.parse(item?.criteria);

                    if (item?.minPrice && _.isNumber(currentCriteria?.index)) {
                        this.at(currentCriteria.index)
                            .set('minPrice', item?.minPrice);
                    }
                });
            },
        });
    }
}
