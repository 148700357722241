/* eslint-disable */
import './popularDirections.css';

import popularCountryViewTemplate from './popularCountryViewTemplate.html';

import ResortsRepository from "@/js/common/Domain/Repository/Resorts";
import CountriesRepository from "@/js/common/Domain/Repository/Countries";

var popularDirectionsViewModel = new (Backbone.Model.extend({
	defaults: {
		departureCityId: null
	}
}))();

var PopularResortView = Backbone.Epoxy.View.extend({
	viewModel: popularDirectionsViewModel,
	computeds: {
		resortLink: {
			deps: ['departureCityId', 'id', 'country'],
			get: function (departureCityId, resortId, country) {
				var link = '/search#?' + $.param({
					fromCity: departureCityId,
					toCountry: country.get('id'),
					toCity: resortId
				});
				return link;
			}
		}
	},
	bindings: {
		'.popularDirection__resortLink': 'attr:{href:resortLink}'
	},
	el: '<li class="popularDirection__resort"></li> ',
	template: _.template('<a href="#" class="popularDirection__resortLink"><%=name %></a>'),
	render: function () {
		this.$el.append(this.template({
			name: this.model.get('name')
		}));
	},
	initialize: function () {
		this.render();
	}
});

var PopularCountryView = Backbone.Epoxy.View.extend({
	viewModel: popularDirectionsViewModel,
	computeds: {
		countryLink: {
			deps: ['departureCityId', 'id'],
			get: function (departureCityId, countryId) {
				var link = '/search#?' + $.param({
					fromCity: departureCityId,
					toCountry: countryId
				});
				return link;
			}
		}
	},
	bindings: {
		'.popularDirection__resorts': 'collection:$collection',
		'.popularDirection__minPrice': 'toggle:minPrice, attr:{href:countryLink}',
		'.popularDirection__minPriceValue': 'text:formatNumber(minPrice)',
		'.popularDirection__countryLink': 'attr:{href:countryLink}',
		'.popularDirection__image': 'attr:{href:countryLink}'
	},
	itemView: PopularResortView,
	el: '<li class="popularDirection"></li>',
	render: function () {
		var visaLabel = this.model.get('visaOnline') ? 'Онлайн-виза' : (this.model.get('visaRequired') ? 'Виза' : 'Без визы'),
			data = {
				id: this.model.get('id'),
				name: this.model.get('name'),
				visaLabel: visaLabel
			},
			html = popularCountryViewTemplate(data);

		this.$el.append(html);
	},
	loadResorts: function () {
		var self = this;
		this.model.getResorts({
			success: function (resorts) {
				self.changeResorts(null, resorts);
			}
		});
	},
	changeResorts: function (model, resorts) {
		var bestResorts = resorts.where({
			isPopular: true
		}).slice(0, 6);

		if (bestResorts && bestResorts.length) {
			this.collection.reset(bestResorts);
		}
	},
	initModelEvents: function () {
		this.model.on('change:resorts', $.proxy(this.changeResorts, this))
	},
	initialize: function () {
		this.collection = new ResortsRepository();
		this.render();
		this.loadResorts();
		this.initModelEvents();

	}
});

let PopularDirectionsView = Backbone.Epoxy.View.extend({
	viewModel: popularDirectionsViewModel,
	itemView: PopularCountryView,
	events: {
		'click .popularDirections__watchAll': 'scrollToAtlasCountries'
	},
	bindings: {
		'.popularDirections__list': 'collection:$collection'
	},

	scrollToAtlasCountries: function () {
		$('html, body').animate({ scrollTop: $('.atlasCountries').offset().top - 150 }, 500);
	},
	countriesChanged: function (model, countries) {
		var popularCountries = countries.where({
			isPopular: true
		}).slice(0, 3);

		if (popularCountries.length) {
			this.collection.reset(popularCountries);
		}
	},
	departureCityChanged: function (model, departureCity) {
		if (departureCity) {
			this.viewModel.set('departureCityId', departureCity.get('id'));
		}
	},
	initModelEvents: function () {
		this.model.get('countries').on('change:collection', $.proxy(this.countriesChanged, this));
		this.model.get('departureCity').on('change:model', $.proxy(this.departureCityChanged, this));
	},
	initialize: function (options) {
		this.collection = new CountriesRepository();
		this.initModelEvents();
	}
});

export default PopularDirectionsView;