module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="minPrice'+
((__t=(country.get('minPrice') ? ' show' : '' ))==null?'':__t)+
'">от '+
((__t=(country.get("minPrice") ? country.get ("minPrice").formatNumber() : ""))==null?'':__t)+
' руб./чел.</span>\n<div class="countryName decoratedSimpleCheckbox">\n    <label>\n        <div class="checkbox-container">\n            <input type="checkbox" class="checkboxInput"'+
((__t=(selected ? ' checked' : ''))==null?'':__t)+
'>\n            <div class="checkbox"></div>\n            <span>\n				'+
((__t=(country.get("name") ))==null?'':__t)+
'\n				<span class="visaLabel">'+
((__t=(country.get("visaRequired") ? (country.get('visaOnline') ? 'онлайн-виза' : 'виза')  : 'без визы' ))==null?'':__t)+
'</span>\n			</span>\n        </div>\n    </label>\n</div>';
}
return __p;
};
