import DepartureCityView from '@/js/desktop/View/DepartureCity';
import NightRangeView from '@/js/desktop/View/NightRange';
import TouristGroupDesktop
    from '@/js/desktop/components/searchFormAutocomplete/searchForm/touristGroup/TouristGroupDesktop';
import HotelView from '@/js/desktop/View/Hotel';
import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';
import CheckInDateRangeHot from './remoteTC_CheckInDateRangeHot';
import CountriesView from './remoteTC_CountriesHot';
import searchFormHotToursModel from './searchFormHotToursModel';

export default Backbone.View.extend({
    dataLayerAnalytics: new DataLayerAnalytics({
        isHotTourView: true,
    }),
    isValid() {
        return true;
    },
    initialize(params) {
        const countriesRepository = params && params.countriesRepository ? params.countriesRepository : false;

        this.model = searchFormHotToursModel({
            countriesRepository,
        });

        this.initBackboneViews();
    },

    initBackboneViews() {
        const modelPrefix = '_hotTours';
        this.initDepartureCityView();
        this.initCountriesView();
        this.initCheckInDateRangeHot(modelPrefix);
        this.initNightRangeView(modelPrefix);
        this.initTouristGroupView();
        this.initHotelView();
    },

    initDepartureCityView() {
        this.model.get('departureCity').set('view', new DepartureCityView({
            model: this.model.get('departureCity'),
            el: '.js-hot-tour-search-form .from_city',
            openCallback: () => {
                this.dataLayerAnalytics.generateEvent('changeCity', '');
            },
        }));
    },

    initCountriesView() {
        this.model.get('countries').set('view', new CountriesView({
            model: this.model.get('countries'),
            departureCityData: this.model.get('departureCity'),
            checkInDateRangeData: this.model.get('checkInDateRange'),
            nightRangeData: this.model.get('nightRange'),
            parentModel: this.model,
            el: '.js-hot-tour-search-form .country',
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));
    },

    initCheckInDateRangeHot(modelPrefix) {
        this.model.get('checkInDateRange').set('view', new CheckInDateRangeHot({
            model: this.model.get('checkInDateRange'),
            el: '.js-hot-tour-search-form .forDates .formDropdown',
            modelPrefix,
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));
    },

    initNightRangeView(modelPrefix) {
        this.model.get('nightRange').set('view', new NightRangeView({
            model: this.model.get('nightRange'),
            departureCityData: this.model.get('departureCity'),
            checkInDateRange: this.model.get('checkInDateRange'),
            modelPrefix,
            el: '.js-hot-tour-search-form .formDropdown.nights',
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));
    },

    initTouristGroupView() {
        this.model.get('touristGroup').set('view', new TouristGroupDesktop({
            model: this.model.get('touristGroup'),
            el: '.js-hot-tour-search-form .touristGroup',
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));
    },

    initHotelView() {
        this.model.get('hotels').set('view', new HotelView({
            model: this.model.get('hotels'),
            searchModel: this.model,
            el: '.selectHotel .hotel',
        }));
    },
});