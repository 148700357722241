import moment from 'moment';

import { extendDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import toRouterHotelHydrator from '@/js/common/Domain/Hydrator/toRouterHotel';
import toRouterHydrator from '@/js/common/Domain/Hydrator/toRouter';

@viewModelDecorator({
    defaults: {
        departureCity: null,
        nightRange: null,
        checkInDateRange: null,
        touristGroup: null,
        hotels: [],
        resorts: [],
        minPrice: 0,
    },

    computeds: {
        departureCityText: {
            deps: ['departureCity'],
            get(departureCity) {
                if (!departureCity) {
                    return '';
                }

                const departureCityJSON = departureCity.toJSON();
                return departureCityJSON.name;
            },
        },

        nightRangeText: {
            deps: ['nightRange', 'checkInDateRange'],
            get(nightRange, checkInDateRange) {
                if (!checkInDateRange || !nightRange) {
                    return '';
                }

                const nightRangeJSON = nightRange.toJSON();
                const checkInDateRangeJSON = checkInDateRange.toJSON();

                const formattedFromDate = moment(checkInDateRangeJSON.from, 'DD.MM.YYYY').format('DD.MM');
                const formattedToDate = moment(checkInDateRangeJSON.to, 'DD.MM.YYYY').format('DD.MM');

                return `с ${formattedFromDate}. по ${formattedToDate}. на ${nightRangeJSON.from}-${nightRangeJSON.to} ноч${nightRangeJSON.to.wordEnding('ь;и;ей')}`;
            },
        },

        touristsGroupText: {
            deps: ['touristGroup'],
            get(touristGroup) {
                if (!touristGroup) {
                    return '';
                }

                const touristGroupJSON = touristGroup.toJSON();
                const childCount = touristGroupJSON.infants + touristGroupJSON.kids;
                let text = `${touristGroupJSON.adults} взросл${touristGroupJSON.adults.wordEnding('ый;ых;ых')}`;

                if (childCount) {
                    text += ` и ${childCount} ${childCount.wordEnding('ребенок;детей;детей')}`;
                }

                return text;
            },
        },

        minPriceText: {
            deps: ['minPrice'],
            get(minPrice) {
                if (minPrice) {
                    return `от ${minPrice.formatNumber()}`;
                }

                return 'УЗНАТЬ ЦЕНУ';
            },
        },
    },
})
@extendDecorator({
    events: {
        click: 'openHistorySearch',
    },
})
export default class HistorySearchItemView extends Backbone.Epoxy.View {
    initialize() {
        this.initViewModel();
    }

    initViewModel() {
        const tourCriteriaJSON = this.model?.get('tourCriteria')?.toJSON();
        if (!tourCriteriaJSON) {
            return;
        }

        const fields = _.pick(tourCriteriaJSON, [
            'departureCity',
            'countries',
            'nightRange',
            'checkInDateRange',
            'touristGroup',
            'hotels',
            'resorts',
            'description',
        ]);

        this.viewModel.set(fields);

        this.viewModel.mapState('minPrice', this.model);
    }

    /**
     * Абстрактный метод, для имплементации в наследуюемых классах.
     */
    render() {}

    openHistorySearch() {
        const tourCriteria = this.model?.get?.('tourCriteria');

        if (!tourCriteria) return;
        const hotels = tourCriteria.get('hotels');

        if (hotels?.length === 1) {
            const hotelId = hotels.at(0).get('id');

            const urlParams = toRouterHotelHydrator({ tourCriteria });
            const url = `/goto/hotel/${hotelId}#?${$.param(urlParams)}`;

            window.open(url, '_blank');
            return;
        }

        const urlParams = toRouterHydrator(tourCriteria);
        urlParams.sort = 'priceUp';
        const url = `/search#?${$.param(urlParams)}`;

        window.open(url, '_blank');
    }
}
