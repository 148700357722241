import searchHistoryService from '@/js/common/Domain/Service/searchHistory';
import shortTourCriteriaFactory from './shortTourCriteriaFactory';

import ShortTourCriteriaCollection from './ShortTourCriteriaCollection';
import HistorySearchItemModel from './HistorySearchItemModel';

export default class HistorySearchView extends Backbone.Epoxy.View {
    initialize() {
        this.initCollection();
        this.fillCollection();
    }

    initCollection() {
        this.collection = new ShortTourCriteriaCollection();
    }

    fillCollection() {
        const shortSearchHistory = searchHistoryService
            .getLastSearches()
            .slice(0, 5);

        const historyItems = _.map(shortSearchHistory, (tourCriteria) => {
            const historySearchItemModel = new HistorySearchItemModel({
                tourCriteria: shortTourCriteriaFactory(tourCriteria),
            });

            return historySearchItemModel;
        }) || [];

        this.collection.reset(historyItems);
    }
}
