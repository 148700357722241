/* eslint-disable */
import 'jquery.cookie';
import useVueCompositionApi from '@/js/common/plugins/use-vue-composition-api';

import ls from 'local-storage';
import '@/js/desktop/globalIncludes';
import '@/js/desktop/globalComponents';

import './index/opeatorsList/operatorsList.css';
import './index/advantages/advantages.css';
import './index/mobileAppBanner/mobileApp.css';
import './index/ourStatistic/ourStatistic.css';
import './index/press/press.css';
import './index/seoBlock/seo.scss';
import './index/atlasCountries/atlasCountries.css';
import searchHistoryService from '@/js/common/Domain/Service/searchHistory';
import toLocalStorageTourCriteriaHydrator from '@/js/common/Domain/Hydrator/toLocalStorageTourCriteria';
import SearchRouter from '@/js/common/Domain/Router/SearchRouter';
import toRouterHotelHydrator from '@/js/common/Domain/Hydrator/toRouterHotel';
import toRouterHydrator from '@/js/common/Domain/Hydrator/toRouter';
import tourCriteriaService from '@/js/common/Domain/Service/tourCriteria';
import HistorySearchView from '@/js/desktop/components/historySearch/HistorySearch';
import PriceCalendarCountriesView from './index/priceCalendar/PriceCalendarCountries';
import PriceWarrantyBlockView from '@/js/common/components/priceWarrantyBlock';
import TestimonialsView from './index/testimonials/Testimonials';

import './index/index.css';
import wildcardDomain from "@/js/common/Domain/Service/wildcardDomain";
import SubscribeEmailFormView from "@/js/desktop/components/subscribeForm/subscribeFormIndex";
import toRouterHotHydrator from "@/js/common/Domain/Hydrator/toRouterHot";
import SearchFormAutocomplete from "@/js/desktop/components/searchFormAutocomplete/SearchFormAutocomplete";
import PopularDirections from "@fr/pages/desktop/travelata/travelata/index/index/popularDestinations/PopularDirections";
import IndexPageFormsTabs
	from "./index/indexPageFormsTabs/indexPageFormsTabs";
import CountriesRepository from "@/js/common/Domain/Repository/Countries";
import SearchFormHotTours from "@fr/pages/desktop/travelata/travelata/hot-tours/components/SearchFormHotTours";
import generalLeadLoader from '@/js/desktop/components/generalLead/generalLeadLoader';
import IndexTrackingService from '@ve/pages/index/analytics/yandexAnalytics/indexTrackingService';

var searchTours = function () {
		var currentTourCriteria = this.model.getTourCriteria(),
			paramsForLocalStorage = toLocalStorageTourCriteriaHydrator(currentTourCriteria),
			paramsForRouter,
			goToUrl,
			router = new SearchRouter().getInstance();

		if (currentTourCriteria.isValid() && this.searchFormView.isValid()) {
			$.cookie('requestFromMainPage', true, {path: '/', domain: wildcardDomain()});
			$.cookie('markSearchSource', true, {path: '/', domain: wildcardDomain()});

            ls.set('tourCriteria', paramsForLocalStorage);

            searchHistoryService.addSearch(currentTourCriteria);

			if (currentTourCriteria.get('hotels') && currentTourCriteria.get('hotels').length === 1) {
				this.indexTrackingService.searchFormHotelNameSearchClick();
				paramsForRouter = toRouterHotelHydrator({tourCriteria: currentTourCriteria});
				goToUrl = '/goto/hotel/' + currentTourCriteria.get('hotels').at(0).get('id') + '#?' + $.param(paramsForRouter);
			} else {
				paramsForRouter = toRouterHydrator(currentTourCriteria);
				goToUrl = '/search#?' + $.param(paramsForRouter);
			}

			if(this.searchFormView.dataLayerAnalytics){
				this.searchFormView.dataLayerAnalytics.generateEvent('clickSearchButtonEvent', '');
			}
			router.go(goToUrl);
		}
	},
	countriesHotToursRepository = new CountriesRepository(),
	searchHotTours = function () {
		var currentTourCriteria = this.model.getTourCriteria(),
			paramsForLocalStorage = toLocalStorageTourCriteriaHydrator(currentTourCriteria),
			goToUrl,
			router = new SearchRouter().getInstance(),
			paramsForRouter = toRouterHotHydrator(currentTourCriteria);

		if (currentTourCriteria.isValid()) {
			ls.set('tourCriteria/tury', paramsForLocalStorage);
			goToUrl = '/tury#?' + $.param(paramsForRouter);
			if(this.searchFormView.dataLayerAnalytics){
				this.searchFormView.dataLayerAnalytics.generateEvent('clickHotButton', '');
			}
			router.go(goToUrl);
			$(this).data('redirectUrl', goToUrl);
		}
	},
	setFormIndexPageView = function (viewFormName, storageName) {
		return {
			events: {
				'click #startSearch, .startSearch': 'startSearchClick',
			},
			countriesRepository: viewFormName === "SearchFormHotTours" ? countriesHotToursRepository : false,
			initModels: function () {
				console.time('init');
				this.searchFormView = new ((viewFormName === 'SearchFormAutocomplete') ? SearchFormAutocomplete : SearchFormHotTours)({
					countriesRepository:viewFormName === "SearchFormHotTours" ? countriesHotToursRepository : false
				});
				this.model = this.searchFormView.model;
			},
			initEvents: function () {
				var modelsInitList = ['departureCity', 'countries', 'resorts', 'hotels'],
					self = this;

				_.forEach(modelsInitList, function (name) {
					self.model.get(name).on('change:model', $.proxy(self.model[name + 'Changed'], self));
				});
			},
			loadFormData: function () {
				var tourCriteriaFromLocalStorage,
					self = this,
					modelsSetIsNullList = ['countries', 'resorts', 'hotels', 'checkInDateRange', 'nightRange', 'touristGroup', 'hotelCategories', 'meals', 'priceRange'];

				_.forEach(modelsSetIsNullList, function (modelName) {
					self.model.get(modelName).setNull();
				});


				tourCriteriaFromLocalStorage = ls.get(storageName);

				tourCriteriaService.loadTourCriteriaWithFormData({
					setTourCriteria: tourCriteriaFromLocalStorage || {},
					referenceUrl: viewFormName === "SearchFormHotTours" ? appConfig.getFormDataHot : appConfig.getFormData,
					countriesRepository: viewFormName === "SearchFormHotTours" ? countriesHotToursRepository : false
				}, {
					success: function (tourCriteria) {
						self.model.setTourCriteria(tourCriteria);
						self.model.trigger("formDataLoaded");
					}
				});

			},
			trackSearchFormView: function () {
				if (viewFormName !== "SearchFormHotTours" && this.searchFormView.dataLayerAnalytics) {
					//fixed
					this.searchFormView.dataLayerAnalytics.generateEvent('cacheResults', false);
					this.searchFormView.dataLayerAnalytics.preSearchPagePath();
				}
			},
			initComponents: function () {
				if (viewFormName !== "SearchFormHotTours") {
					new PopularDirections({
						el: '.popularDirections',
						model: this.model
					});
					generalLeadLoader.setModel(this.model);
					new SubscribeEmailFormView({
						el: '.subscribeEmail',
					});
				}
			},
			initialize: function () {
				this.indexTrackingService = new IndexTrackingService();
				tourCriteriaService.clearTourCriteriaChangedFields();
				this.initModels();
				this.initEvents();
				this.trackSearchFormView();
				this.loadFormData();
				this.initComponents();
			}
		}
	};

$(() => {
	var Controller = Backbone.View.extend(setFormIndexPageView("SearchFormAutocomplete", "tourCriteria")),
		ControllerHot = Backbone.View.extend(setFormIndexPageView('SearchFormHotTours', 'tourCriteria/tury')),
		IndexSearchFormController = Controller.extend({
			startSearchClick: searchTours
		}),
		IndexHotSearchFormController = ControllerHot.extend({
			startSearchClick: searchHotTours
		});

	useVueCompositionApi();
	new IndexPageFormsTabs({
		el: ".js-index-page-forms-tabs"
	});

	new IndexSearchFormController({
		el: '.js-tab-search-tours'
	});
	new IndexHotSearchFormController({
		el: '.js-tab-search-hot-tour'
	});

	new HistorySearchView({
		el: $('.searchHistory'),
	});

	new PriceCalendarCountriesView();

	new PriceWarrantyBlockView({
		el: "body"
	});

	new TestimonialsView();
});
