import HotelsRepository from '@/js/common/Domain/Repository/Hotels';
import ResortsRepository from '@/js/common/Domain/Repository/Resorts';
import CountriesRepository from '@/js/common/Domain/Repository/Countries';
import TourCriteriaObjectValue from '@/js/common/Domain/ObjectValue/TourCriteria';
import CheckInDateRangeFactory from '@/js/common/Domain/Factory/checkInDateRange/CheckInDateRange';
import TouristGroupFactory from '@/js/common/Domain/Factory/TouristGroup';
import NightRangeFactory from '@/js/common/Domain/Factory/NightRange';
import CountryEntity from '@/js/common/Domain/Entity/Country';
import ResortEntity from '@/js/common/Domain/Entity/Resort';
import HotelEntity from '@/js/common/Domain/Entity/Hotel';

export default (data) => {
    const tourCriteria = new TourCriteriaObjectValue();

    let countries;
    let resorts;
    let hotels;
    let element;

    element = data.touristGroup;
    if (element) {
        tourCriteria.set('touristGroup', new TouristGroupFactory(element));
    }

    element = data.nightRange;
    if (element) {
        tourCriteria.set('nightRange', new NightRangeFactory(element));
    }

    element = data.checkInDateRange;
    if (element) {
        tourCriteria.set('checkInDateRange', new CheckInDateRangeFactory(element));
    }

    element = data.departureCity;
    if (element) {
        tourCriteria.set('departureCity', new Backbone.Model(element));
    }

    element = data.description;
    if (element) {
        tourCriteria.set('description', element);
    }

    element = data.cover;
    if (element) {
        tourCriteria.set('cover', element);
    }

    element = data.countries;
    if (_.isArray(element)) {
        countries = [];

        _.each(element, (countryId) => {
            const country = new CountryEntity({
                id: countryId,
            });

            countries.push(country);
        });

        tourCriteria.set('countries', new CountriesRepository(countries));
    }

    element = data.resorts;
    if (_.isArray(element)) {
        resorts = [];

        _.each(element, (resortId) => {
            const resort = new ResortEntity({
                id: resortId,
            });
            resorts.push(resort);
        });

        tourCriteria.set('resorts', new ResortsRepository(resorts));
    }

    element = data.hotels;
    if (_.isArray(element)) {
        hotels = [];

        _.each(element, (hotelId) => {
            const hotel = new HotelEntity({
                id: hotelId,
            });
            hotels.push(hotel);
        });

        tourCriteria.set('hotels', new HotelsRepository(hotels));
    }

    return tourCriteria;
};
