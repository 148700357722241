/* eslint-disable */
import './priceCalendar.css';
import AjaxRequestManager from "@/js/common/Domain/AjaxRequestManager";
import DepartureCitiesRepository from "@/js/common/Domain/Repository/DepartureCities";
import SearchFormFieldModel from "@/js/common/Domain/Model/SearchFormField";
import CountriesRepository from "@/js/common/Domain/Repository/Countries";
import TourCriteriaFactory from "@/js/common/Domain/Factory/TourCriteria";
import toRouterHydrator from "@/js/common/Domain/Hydrator/toRouter";
import PriceCalendarIndexView from "./PriceCalendarIndex";
import DepartureCityView from '@/js/desktop/View/DepartureCity';

var ajaxRequestManager = new AjaxRequestManager();

var SearchDataModel = Backbone.Model.extend({
	defaults:{
		searchData:false
	}
});

var PriceCalendarCountriesModel = Backbone.Model.extend({
	defaults:{
		departureCity: new SearchFormFieldModel({
			collection: DepartureCitiesRepository.repository
		}),
		selectedCountry:false,
		searchModel:new SearchDataModel(),
		checkInDateRange:new SearchFormFieldModel({
			model: false
		})
	}
});

let PriceCalendarCountriesView = Backbone.View.extend({
	model:new PriceCalendarCountriesModel(),
	countryTemplate:_.template('<% _.each(countries, function(country, index) { %>\n\t<li<%= index == 0 ? \' class="selected"\' : \'\' %> data-countryid="<%=country.get(\'id\') %>"><%=country.get("name") %></li>\n<% }); %>\n<li><a href="/calendar"><span>Все направления</span> <i class="icon icon-i16_right"></i></a></li>\n'),
	buildCountries:function() {
		var popularCountries = _.sortBy(CountriesRepository.repository.filter(function(country) {
				return country.get('isPopular');
			}), function(country) {
				return country.get('position');
			});

		this.$countriesContainer.append(this.countryTemplate({
			countries:popularCountries
		}));

		this.model.set('selectedCountry', popularCountries[0]);
	},
	setDepartureCity:function() {
		var defaultDepartureCity = DepartureCitiesRepository.repository.get(window.appConfig.defaultDepartureCityId);
		this.model.get('departureCity').set('model', defaultDepartureCity);
	},
	calendarCriteriaChanged:function(model, value, context) {
		var selectedDepartureCity = this.model.get('departureCity').get('model'),
			selectedCountry = this.model.get('selectedCountry'),
			selectedDepartureCityId,
			departureCityType,
			countryTourCriteriaData,
			countryTourCriteria;

		if(selectedDepartureCity && selectedCountry) {
			selectedDepartureCityId = selectedDepartureCity ? selectedDepartureCity.get('id') : null;
			departureCityType = selectedDepartureCityId == 1 || selectedDepartureCityId == 2 ? 'capital' : 'region';
			countryTourCriteriaData = $.extend({}, selectedCountry.get(departureCityType + 'TourCriteria'), {
				departureCity: selectedDepartureCityId,
				countries: [this.model.get('selectedCountry').get('id')]
			});
			countryTourCriteria = new TourCriteriaFactory(countryTourCriteriaData);
			this.model.get('searchModel').set('searchData', countryTourCriteria);
		}
	},
	countryClick:function(e) {
		var $selectedCountry = $(e.target),
			countryId = parseInt($selectedCountry.data('countryid')),
			country = CountriesRepository.repository.get(countryId);

		if(country) {
			this.model.set('selectedCountry', country);
			this.$countriesContainer.find('.selected').removeClass('selected');
			$selectedCountry.addClass('selected');
		}
	},
	calendarDateSelected:function() {
		var searchData = this.model.get('searchModel').get('searchData'),
			checkInDateRangeSelected = this.model.get('checkInDateRange').get('model'),
			mixedCriteria = searchData.clone();

		mixedCriteria.set('checkInDateRange', checkInDateRangeSelected);

		document.location.href = '/search#?sort=priceUp&' + $.param(toRouterHydrator(mixedCriteria));
	},
	initModelEvents:function() {
		this.model.on('change:selectedCountry', $.proxy(this.calendarCriteriaChanged, this));
		this.model.get('departureCity').on('change:model', $.proxy(this.calendarCriteriaChanged, this));
	},
	initDomEvents:function() {
		this.$countriesContainer.on('click', 'li', $.proxy(this.countryClick, this));
	},
	initComponents: function() {
		new PriceCalendarIndexView({
			parent:{
				view:{
					model:this.model.get('searchModel')
				},
				model:this.model,
				formSubmit:$.proxy(this.calendarDateSelected, this)
			},
			numOfCalendarDays: 15
		});
		$('#priceCalendar').show();

		new DepartureCityView({
			model: this.model.get('departureCity'),
			el:'.priceCalendarCountriesContainer .from_city'
		});
	},
	initialize:function(options) {
		var addCountriesTimeout,
			addDepartureCityTimeout,
			self = this;

		DepartureCitiesRepository.repository.on('add', function() {
			clearTimeout(addDepartureCityTimeout);
			addDepartureCityTimeout = setTimeout(function() {
				self.setDepartureCity();
			}, 1);
		});

		CountriesRepository.repository.on('add', function() {
			clearTimeout(addCountriesTimeout);
			addCountriesTimeout = setTimeout(function() {
				self.buildCountries();
			}, 1);
		});

		this.$countriesContainer = $('#priceCalendarCountriesContainer .priceCalendarIndex_countries');

		this.initModelEvents();
		this.initDomEvents();
		this.initComponents();
	}
});

export default PriceCalendarCountriesView;
