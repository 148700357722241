import departureCity from './toRouter/departureCity';
import checkInDateRange from './toRouter/checkInDateRange';
import nightRange from './toRouter/nightRange';
import touristGroup from './toRouter/touristGroup';
import hotelCategories from './toRouter/hotelCategories';
import meals from './toRouter/meals';
import hotels from './toRouter/hotels';
import warranted from './toRouter/warranted';
import isExclusive from './toRouter/isExclusive';

export default (tourCriteria, otherCriteria) => {
    const params = {
        ...departureCity(tourCriteria),
        ...checkInDateRange(tourCriteria),
        ...nightRange(tourCriteria),
        ...touristGroup(tourCriteria),
        ...hotelCategories(tourCriteria),
        ...meals(tourCriteria),
        ...hotels(tourCriteria),
        ...warranted(tourCriteria),
        ...isExclusive(tourCriteria),
        ...otherCriteria,
    };

    const element = tourCriteria.get('countries');
    if (element && element.length) {
        params.toCountries = element.pluck('id').join('.');
    }

    return params;
};