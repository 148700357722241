import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';

import HistorySearchItemView from '@/js/common/components/historySearch/HistorySearchItemView';

import template from './assets/template.html';

@extendDecorator({
    className: 'searchHistory__item',
    bindings: {
        '.js-direction-departureCity': 'text: departureCityText',
        '.js-direction-name': 'text: description',
        '.js-direction-dates': 'text: nightRangeText',
        '.js-direction-touristsGroup': 'text: touristsGroupText',
        '.js-direction-minPrice': 'text: minPriceText',
        '.js-direction-minPrice-icon': 'toggle: not(equal(minPriceText, "УЗНАТЬ ЦЕНУ"))',
    },

    template,
})
export default class MobileHistorySearchItemView extends HistorySearchItemView {
    initialize() {
        super.initialize();

        this.render();
    }

    render() {
        this.$el.append(this.template());
    }
}
