import Vue from 'vue';
import HotelOffersTab from '@ve/components/hotelOffersTab/desktop/HotelOffersTab.vue';
import RailwayToursTab from '@ve/components/railwayToursTab/desktop/RailwayToursTab.vue';

import searchTabTemplate from './searchTabTemplate.html';
import hotTabTemplate from './hotTabTemplate.html';
import hotelOffersTemplate from './hotel-offers-tab-container.html';
import railwayToursTabTemplate from './railway-tours-tab-container.html';

import './indexPageFormsTabs.css';

const IndexPageTabsModel = Backbone.Epoxy.Model.extend({
    defaults: {
        showSearchToursForm: true,
        showSearchHotToursForm: false,
    },
});
const IndexPageFormsTabs = Backbone.Epoxy.View.extend({
    model: new IndexPageTabsModel(),
    bindings: {
        '.js-tab-search-tours': 'toggle:showSearchToursForm',
        '.js-tab-search-hot-tour': 'toggle:showSearchHotToursForm',
        '.tab-search-tours': 'classes:{active:showSearchToursForm}',
        '.tab-search-hot-tours': 'classes:{active:showSearchHotToursForm}',
    },
    events: {
        'click .tab-search-tours': 'showSearchToursForm',
        'click .tab-search-hot-tours': 'showSearchHotToursForm',
    },

    initialize() {
        $('.js-tab-search-tours').find('.from_city').addClass('tab-forms__flex')
            .append(hotTabTemplate)
            .append(hotelOffersTemplate)
            .append(railwayToursTabTemplate);
        $('.js-tab-search-hot-tour').find('.from_city').addClass('tab-forms__flex')
            .prepend(searchTabTemplate)
            .append(hotelOffersTemplate)
            .append(railwayToursTabTemplate);

        $('.js-hotel-offers-tab').each((index, el) => {
            new Vue({
                render: (h) => h(HotelOffersTab),
            }).$mount(el);
        });
        $('.js-railway-tours-tab').each((index, el) => {
            new Vue({
                render: (h) => h(RailwayToursTab),
            }).$mount(el);
        });
    },
    showSearchToursForm() {
        this.model.set('showSearchToursForm', true);
        this.model.set('showSearchHotToursForm', false);
    },
    showSearchHotToursForm() {
        this.model.set('showSearchToursForm', false);
        this.model.set('showSearchHotToursForm', true);
    },

});
export default IndexPageFormsTabs;
