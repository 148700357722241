/* eslint-disable */
import CountriesRepository from '@/js/common/Domain/Repository/Countries';
import tourCriteriasForHotMinPricesHydrator from '@/js/common/Domain/Hydrator/tourCriteriasForHotMinPrices';
import getMinPriceByCriteriasService from '@/js/common/Domain/Service/getMinPriceByCriterias';
import countryTemplate from './template/remoteTC_CountriesHotTemplate.html';

const maxCountriesToSelect = 3;

const CountryView = Backbone.View.extend({
    el: '<li></li>',
    template: countryTemplate,
    $checkboxInput() {
        this.$checkboxInput = $('.checkboxInput', this.$el);
    },
    $minPriceLabel() {
        this.$minPriceLabel = $('.minPrice', this.$el);
    },
    render() {
        this.$el.html(countryTemplate({
            country: this.model,
            selected: this.selected,
        }));
    },
    updateSelection() {
        if (this.parent.innerModel.get('model').find({id: this.model.get('id')})) {
            this.$checkboxInput.prop('checked', true);
        } else {
            this.$checkboxInput.prop('checked', false);
        }
    },
    modelChanged(event, model, context) {
        if ((!context || context.initiator !== 'view') && model !== null) {
            this.updateSelection();
        }
    },
    changeCountries(event) {
        const currentCountries = this.parent.innerModel.get('model');
        const $currentCheckbox = $(event.target);
        let newCountries;

        if ($currentCheckbox.is(':checked')) {
            newCountries = currentCountries.models.concat(this.model);
        } else {
            newCountries = currentCountries.without(this.model);
        }

        if (newCountries && newCountries.length && newCountries.length <= maxCountriesToSelect) {
            this.parent.model.set('model', new CountriesRepository(newCountries), {silent: true});
            this.parent.model.trigger('change:model', null, this.parent.model.get('model'), {trigger: 'manuallyChanged'});
            this.parent.hideLimitWarning();
        } else if (newCountries.length === 0) {
            this.parent.innerModel.set('model', new CountriesRepository(newCountries));
        } else if ($currentCheckbox.is(':checked')) {
            $currentCheckbox.prop('checked', false);
            this.parent.showLimitWarning();
        }
    },
    minPriceChanged(event, minPrice) {
        if (minPrice) {
            this.$minPriceLabel.addClass('show').text(`от ${minPrice.formatNumber()} руб./чел.`);
        } else {
            this.$minPriceLabel.removeClass('show');
        }
    },
    initModelEvents() {
        this.parent.innerModel.on('change:model', $.proxy(this.modelChanged, this));
        this.model.on('change:minPrice', $.proxy(this.minPriceChanged, this));
    },
    initDomEvents() {
        this.$checkboxInput.on('change', $.proxy(this.changeCountries, this));
    },
    initialize(options) {
        this.parent = options.parent;
        this.selected = options.selected ? options.selected : false;
        this.render();
        this.$checkboxInput();
        this.$minPriceLabel();

        this.initModelEvents();
        this.initDomEvents();
    },
});

const CountriesViewModel = Backbone.Model.extend({
    defaults: {
        model: false,
    },
});

const CountriesView = Backbone.View.extend({
    label: 'Страна',
    innerModel: new CountriesViewModel(),
    limitWarningTimeout: null,
    $formDropdown() {
        this.$formDropdown = $('.formDropdown', this.$el);
    },
    $countriesLabel() {
        this.$countriesLabel = $('.formInputPlace p', this.$el);
    },
    $countriesListSelected() {
        this.$countriesListSelected = $('.formDropdownContent .selected', this.$el);
    },
    $limitWarning() {
        this.$limitWarning = $('.formDropdownContent .limitWarning', this.$el);
    },
    $countriesList() {
        this.$countriesList = $('.formDropdownContent .list', this.$el);
    },
    showLimitWarning() {
        const self = this;
        if (this.$limitWarning.is(':visible')) {
            clearTimeout(this.limitWarningTimeout);
        } else {
            this.$limitWarning.slideDown(300);
        }

        this.limitWarningTimeout = setTimeout(() => {
            self.hideLimitWarning();
        }, 3000);
    },
    hideLimitWarning() {
        this.$limitWarning.slideUp(300);
    },
    updateLabel() {
        const countries = this.innerModel.get('model');

        if (countries && countries.length) {
            this.$countriesLabel.removeClass('hint').text(countries.pluck('name').join(', '));
        } else {
            this.$countriesLabel.addClass('hint').text('Выберите направление');
        }
    },
    updateCountries() {
        this.updateLabel();
        this.updateSelectedCountries();
    },
    updateSelectedCountries() {
        const countries = this.innerModel.get('model');
        const self = this;

        this.$countriesListSelected.empty();
        countries.each((country) => {
            const countryModel = new CountryView({
                parent: self,
                model: country,
                selected: true,
            });
            self.$countriesListSelected.append(countryModel.$el);
        });

        if (countries && countries.length) {
            self.$countriesListSelected.show();
        } else {
            self.$countriesListSelected.hide();
        }
    },
    renderCountriesList(countriesCollection) {
        const self = this;

        this.$countriesList.empty();
        countriesCollection.each((country) => {
            const countryModel = new CountryView({
                parent: self,
                model: country,
            });
            self.$countriesList.append(countryModel.$el);
        });
    },
    checkControlSelection() {
        if (!this.innerModel.get('model').length) {
            this.cloneModel();
        }
    },
    hideDropdown() {
        if (this.$formDropdown.find('.formDropdownContent:visible').length) {
            this.checkControlSelection();
        }
        this.$formDropdown.find('.formDropdownContent:visible').hide();
        this.$formDropdown.find('.formInput.open').removeClass('open');
    },
    showDropdown() {
        this.$formDropdown.find('.formDropdownContent:hidden').show();
        this.$formDropdown.find('.formInput').addClass('open');
    },
    cloneModel() {
        this.innerModel.set('model', this.model.get('model'));
    },
    clearMinPrices() {
        const countries = this.model.get('collection');
        countries.each((country) => {
            country.set('minPrice', null);
        });
    },
    async getMinPrices() {
        const countries = this.model.get('collection').filter(country => country.get('isPopular'));
        const departureCity = this.departureCityData.get('model').get('id');
        const tourCriterias = tourCriteriasForHotMinPricesHydrator({
            departureCity,
            countries,
            currentTourCriteria: this.parentModel.getTourCriteria(),
        });
        const pricesData = await getMinPriceByCriteriasService({
            tourCriterias,
        });

        _.each(pricesData, (countryPrice) => {
            const criteria = JSON.parse(countryPrice.criteria);
            _.find(countries, country => country.get('id') === criteria.countries[0]).set('minPrice', Math.round(countryPrice.minPrice / 2));
        });
    },
    modelChanged(event, model) {
        if (model === null) {
            this.$formDropdown.addClass('disable');
        } else {
            this.$formDropdown.removeClass('disable');
            this.updateCountries();
        }
    },
    collectionChanged(event, collection) {
        this.renderCountriesList(collection);
    },
    departureCityChanged() {
        const countries = this.model.get('collection');
        if (countries && countries.length) {
            this.clearMinPrices();
            this.getMinPrices();
        }
    },
    nightRangeChanged(event, model, context) {
        if (model !== null && context.initiator === 'view') {
            this.clearMinPrices();
        }
    },
    checkInDateRangeChanged(event, model, context) {
        if (model !== null && context.initiator === 'view') {
            this.clearMinPrices();
        }
    },
    updateMinPrices() {
        this.getMinPrices();
    },
    initModelEvents() {
        this.innerModel.on('change:model', $.proxy(this.modelChanged, this));
        this.model.on('change:collection', $.proxy(this.collectionChanged, this));
        this.model.on('change:model', $.proxy(this.cloneModel, this));

        this.departureCityData.on('change:model', $.proxy(this.departureCityChanged, this));
        this.checkInDateRangeData.on('change:model', $.proxy(this.checkInDateRangeChanged, this));
        this.nightRangeData.on('change:model', $.proxy(this.nightRangeChanged, this));
        this.parentModel.on('submitSearch', $.proxy(this.updateMinPrices, this));
        this.parentModel.once('formDataLoaded', $.proxy(this.updateMinPrices, this));
    },

    initDOMEvents() {
        const self = this;

        this.$countriesLabel.parents('.formInput').on('click', function () {
            if (!self.$formDropdown.hasClass('disable')) {
                if ($(this).hasClass('open')) {
                    self.hideDropdown();
                } else {
                    self.showDropdown();
                    self.dataLayerAnalyticsEvent();
                }
            }
        });
        $(document).click((event) => {
            if (!($(event.target).parents('.formControl.country').length || $(event.target).hasClass('checkboxInput'))) {
                self.hideDropdown();
            }
        });

        this.$limitWarning.find('.count').text(`${maxCountriesToSelect} направлен${maxCountriesToSelect.wordEnding('ие;ия;ий')}`);
    },
    dataLayerAnalyticsEvent() {
        if (this.dataLayerAnalytics) {
            this.dataLayerAnalytics.searchFieldsEvent('countryToOpen');
        }
    },
    initialize(options) {
        this.departureCityData = options.departureCityData;
        this.checkInDateRangeData = options.checkInDateRangeData;
        this.nightRangeData = options.nightRangeData;
        this.parentModel = options.parentModel;
        this.dataLayerAnalytics = options.dataLayerAnalytics;
        this.$formDropdown();
        this.$countriesLabel();
        this.$countriesList();
        this.$limitWarning();
        this.$countriesListSelected();

        this.initDOMEvents();
        this.initModelEvents();
    },
});
export default CountriesView;