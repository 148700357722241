import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import moment from 'moment';
import 'moment/locale/ru';
import PriceCalendarModel from '@/js/common/components/PriceCalendar/PriceCalendarModel';

@extendDecorator({
    computeds: {
        expandedDatesObject() {
            const { numOfCalendarDays } = this;
            const currentDate = moment().add(1, 'days').startOf('day');
            const needDates = numOfCalendarDays - 1;
            const calendarOffset = this.get('offset') * numOfCalendarDays;
            const dateFrom = currentDate.clone().add(calendarOffset, 'days');
            const dateTo = currentDate.clone().add(calendarOffset + needDates, 'days');

            return {
                from: dateFrom,
                to: dateTo,
            };
        },
    },
})
export default class PriceCalendarIndexModel extends PriceCalendarModel {}
