import { extendDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';

import HistorySearchView from '@/js/common/components/historySearch/HistorySearch';
import HistorySearchItemView from './components/historySearchItem/HistorySearchItem';

import './assets/styles.scss';
import template from './assets/template.html';

@viewModelDecorator({
    searchResultsAmount: 0,
})
@extendDecorator({
    bindings: {
        ':el': 'toggle: searchResultsAmount',
        '.js-history-cards': 'collection: $collection',
    },

    template,
    itemView: HistorySearchItemView,
})
export default class DesktopHistorySearchView extends HistorySearchView {
    initialize() {
        this.render();

        super.initialize();
    }

    render() {
        const $template = $(this.template());
        this.$el.html($template);
    }

    fillCollection() {
        super.fillCollection();

        this.viewModel.set('searchResultsAmount', this.collection.length);
    }
}
