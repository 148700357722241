module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="#" class="popularDirection__image" style="background-image:url(\'/travelata/img/mail/tourhunter/countries/'+
((__t=(id ))==null?'':__t)+
'.jpg\')"></a>\r\n<div class="popularDirection__content">\r\n	<div class="popularDirection__country">\r\n		<span class="flagIco s21 s21_b1 flag_'+
((__t=(id ))==null?'':__t)+
'"></span>\r\n		<a href="#" class="popularDirection__countryLink">'+
((__t=(name ))==null?'':__t)+
'<span>'+
((__t=(visaLabel ))==null?'':__t)+
'</span></a>\r\n	</div>\r\n	<ul class="popularDirection__resorts"></ul>\r\n	<a href="#" class="popularDirection__minPrice">от <span class="popularDirection__minPriceValue"></span> руб./чел.</a>\r\n</div>\r\n';
}
return __p;
};
