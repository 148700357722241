/* eslint-disable */
import moment from "moment";
import 'moment/locale/ru';
import {TrackJS} from "trackjs";

import CheckInDateRangeFactory from '@/js/common/Domain/Factory/checkInDateRange/CheckInDateRange';
import tourCriteriaService from "@/js/common/Domain/Service/tourCriteria";
import getStore from "@ve/services/getStore/getStore";
import { CheckInDateRangeStore, NAMESPACE as CHECK_IN_DATE_RANGE_STORE } from '@entities/searchCriteria/checkInDate';

var PeriodView = Backbone.View.extend({
	el: '<li></li>',
	events: {
		"click": "periodSelected"
	},
	periodData: null,
	render: function () {
		this.$el.append(this.periodData.name);
	},
	periodSelected: function () {
		var checkInDateRange,
			period = this.periodData;

		checkInDateRange = CheckInDateRangeFactory({
			from: period.from.toDate(),
			to: period.to.toDate()
		});

		if (checkInDateRange && checkInDateRange.get('from') && checkInDateRange.get('to')) {

			this.parent.model.set('model', checkInDateRange, {initiator: 'view'});
			this.parent.updateDateText();
			this.parent.hideDropdown(this.parent.$el);

			tourCriteriaService.addTourCriteriaChangedField('checkInDateRange');
			console.log('Changed CheckInDateRange to' + checkInDateRange.get('from') + '-' + checkInDateRange.get('to'));

		} else {
			console.log(period.from.toDate(), period.to.toDate());
			TrackJS.track("Wrong date from datepicker");
		}
	},
	initialize: function (options) {
		this.periodData = options.periodData;
		this.parent = options.parent;
		this.render();
	}
});

let CheckInDateRangeHot = Backbone.View.extend({
	label: 'Дата вылета',
	weekends: [
		{
			name: 'новогодние праздники',
			from: moment('28.12', 'DD.MM'),
			to: moment('05.01', 'DD.MM')
		},
		{
			name: 'майские праздники',
			from: moment('28.04', 'DD.MM'),
			to: moment('03.05', 'DD.MM')
		}
	],
	periods: [],
	$date: function () {
		this.$date = $('.date', this.$el);
	},
	$periodsList: function () {
		this.$periodsList = $('.formDropdownContent ul', this.$el);
	},

	scrollToElement: function (e) {
		var currenPosition = $(document).scrollTop(),
			windowHeight = $(window).height(),
			headerHeight = $('.headerContainer').height(),
			menuHeight = $('.menuContainer').height(),
			elementPosition = this.$date.offset().top,
			self = this,
			scrollTo;

		scrollTo = elementPosition;

		headerHeight ? scrollTo -= headerHeight : '';
		menuHeight ? scrollTo -= menuHeight : '';

		scrollTo -= 50;

		setTimeout(function () {
			self.showDropdown(self.$el);
		}, 1);

		if (elementPosition > currenPosition + windowHeight || elementPosition < currenPosition) {
			$("html, body").animate({scrollTop: scrollTo}, 200);
			return true;
		}

		return false;
	},
	hideDropdown: function ($element) {
		var self = this;
		$element.parents('.formControl').find('.formDropdownContent:visible').hide();
		$element.parents('.formControl').find('.formInput.open').removeClass('open');
		clearInterval(self.gaTimer);
	},
	showDropdown: function ($element) {
		var self = this;
		$element.parents('.formControl').find('.formDropdownContent:hidden').show();
		$element.parents('.formControl').find('.formInput').addClass('open');
	},
	setDate: function () {
		this.updateDateText();
	},
	addPeriod: function (period) {
		var weekends = this.weekends,
			insertBefore = [],
			insertAfter = [];

		_.each(this.weekends, function (weekend) {
			if (!weekend.added) {
				var tomorrow = moment().startOf('day').add(1, 'day'),
					weekendFrom = weekend.from,
					weekendTo = weekend.to,
					periodFrom = period.from,
					periodTo = period.to;

				if (weekendTo < weekendFrom && !weekend.alreadyMofidied) {
					weekendTo.add(1, 'year');
					weekend.alreadyMofidied = 1;
				}

				if ((weekendFrom < periodFrom && weekendTo > periodFrom)) {
					insertBefore.push(weekend);
					weekend.added = 1;
				} else if (weekendFrom < periodTo && weekendTo > periodTo) {
					insertAfter.push(weekend);
					weekend.added = 1;
				} else if ((weekendTo > tomorrow || weekendTo.isSame(tomorrow)) && (weekendTo < periodFrom || weekendTo.isSame(periodFrom))) {
					insertBefore.push(weekend);
					weekend.added = 1;
				}
			}
		});

		this.periods = this.periods.concat(insertBefore, [period], insertAfter);
	},
	calculatePeriods: function () {
		var tomorrow = moment().add(1, 'day').startOf('day'),
			maxDate = tomorrow.clone().startOf('month').add(1, 'month'),
			plusTwoWeeksDay = tomorrow.clone().add(13, 'days'),
			currentMonth = plusTwoWeeksDay.clone().startOf('month'),
			plusTwoWeeksDayDate = plusTwoWeeksDay.date();

		this.periods = this.periods.concat([
			{
				name: 'ближайшие 3 дня',
				from: tomorrow,
				to: tomorrow.clone().add(2, 'days')
			},
			{
				name: 'ближайшая неделя',
				from: tomorrow,
				to: tomorrow.clone().add(6, 'days')
			},
			{
				name: 'ближайшие 2 недели',
				from: tomorrow,
				to: plusTwoWeeksDay.clone()
			}
		]);

		currentMonth.startOf('month');
		if (plusTwoWeeksDayDate < 14) {
			this.addPeriod({
				name: currentMonth.format('начало D MMMM').replace(/\d+\s/, ''), //hack for correct month ending
				from: currentMonth.clone().startOf('month'),
				to: currentMonth.clone().date(14)
			});
		}

		if (!plusTwoWeeksDay.isSame(currentMonth.clone().endOf('month').startOf('day'))) {
			this.addPeriod({
				name: currentMonth.format('конец D MMMM').replace(/\d+\s/, ''), //hack for correct month ending
				from: currentMonth.clone().date(15),
				to: currentMonth.clone().endOf('month').startOf('day')
			});
		}

		currentMonth.add(1, 'month');
		while (currentMonth < maxDate) {
			this.addPeriod({
				name: currentMonth.format('MMMM'),
				from: currentMonth.clone().startOf('month'),
				to: currentMonth.clone().endOf('month').startOf('day')
			});

			currentMonth.add(1, 'month');
		}
	},
	renderPeriods: function () {
		var periods = this.periods,
			self = this;

		_.each(periods, function (period) {
			var periodView = new PeriodView({
				periodData: period,
				parent: self
			});
			self.$periodsList.append(periodView.$el);
		});
	},
	updateDateText: function () {
		var datesText,
			checkInDateRange = this.model.get('model'),
			dateFrom = checkInDateRange.get('from'),
			dateTo = checkInDateRange.get('to'),
			periods = this.periods,
			selectedPeriod;

		selectedPeriod = _.find(periods, function (period) {
			return period.from.isSame(dateFrom) && period.to.isSame(dateTo);
		});

		if (selectedPeriod) {
			datesText = selectedPeriod.name;
		} else {
			if (dateFrom.getTime() !== dateTo.getTime()) {
				datesText = 'c ' + moment(dateFrom).format('DD.MM') + ' по ' + moment(dateTo).format('DD.MM');
			} else {
				datesText = "с " + moment(dateFrom).format('DD.MM.YYYY');
			}
		}

		this.$date.text(datesText);
	},
	initDOMEvents: function () {
		var self = this;
		this.$date.parents('.formInput').click(function () {
			if (!self.$el.hasClass('disable')) {
				if ($(this).hasClass("open")) {
					self.hideDropdown($(this));
				} else {
					self.showDropdown($(this));
					self.dataLayerAnalyticsEvent();
				}
			}
		});

		$(document).click(function (event) {
			if (!($(event.target).parents('.formControl.forDates').length)) {
				self.hideDropdown(self.$el);
			}
		});
	},
	dataLayerAnalyticsEvent(){
		if(this.dataLayerAnalytics){
			this.dataLayerAnalytics.searchFieldsEvent('datesOpen')
		}
	},
	modelChanged: function (event, model, context) {
		if (!context || context.initiator !== 'view') {
			if (model === null) {
				this.$el.addClass('disable');
			} else {
				this.$el.removeClass('disable');
				this.setDate();
			}
		}
		this.updateCheckInDateRangeStore(model);
	},
	updateCheckInDateRangeStore(model) {
		if (model) {
			const store = getStore();
			store.commit(`${CHECK_IN_DATE_RANGE_STORE}${this.modelPrefix}/setCheckInDateRange`, {
				from: model.get('from'),
				to: model.get('to'),
			});
		}
	},
	initEvents: function () {
		this.model.on('change:model', $.proxy(this.modelChanged, this));
	},
	initialize: function (options) {
		var self = this;
		this.$date();
		this.$periodsList();
		this.dataLayerAnalytics = options.dataLayerAnalytics;
		this.modelPrefix = options.modelPrefix ? options.modelPrefix : '';
		this.initEvents();
		this.initDOMEvents();
		this.calculatePeriods();
		this.renderPeriods();
		const store = getStore();
		store.registerModule(`${CHECK_IN_DATE_RANGE_STORE}${this.modelPrefix}`, CheckInDateRangeStore);
	}
});
export default CheckInDateRangeHot
