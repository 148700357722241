/* eslint-disable */
import SearchFormModel from '@/js/common/Domain/Model/SearchForm';
import SearchFormFieldModel from '@/js/common/Domain/Model/SearchFormField';
import DepartureCitiesRepository from '@/js/common/Domain/Repository/DepartureCities';
import CountriesRepository from '@/js/common/Domain/Repository/Countries';
import ResortsRepository from '@/js/common/Domain/Repository/Resorts';
import HotelsRepository from '@/js/common/Domain/Repository/Hotels';
import SortFactory from '@/js/common/Domain/Factory/Sort';

export default (params) => {
    const {countriesRepository} = params;

    return new SearchFormModel({
        departureCity: new SearchFormFieldModel({
            collection: DepartureCitiesRepository.repository,
        }),
        countries: new SearchFormFieldModel({
            collection: new CountriesRepository(),
            model: new CountriesRepository(),
            countriesRepository,
        }),
        resorts: new SearchFormFieldModel({
            collection: new ResortsRepository(),
            model: new ResortsRepository(),
        }),
        hotels: new SearchFormFieldModel({
            model: new HotelsRepository(),
        }),
        checkInDateRange: new SearchFormFieldModel({
            model: false,
        }),
        nightRange: new SearchFormFieldModel({
            model: false,
        }),
        touristGroup: new SearchFormFieldModel({
            model: false,
        }),
        hotelCategories: new SearchFormFieldModel({
            model: false,
        }),
        meals: new SearchFormFieldModel({
            model: false,
        }),
        priceRange: new SearchFormFieldModel({
            model: false,
        }),
        sort: new SearchFormFieldModel({
            model: new SortFactory(),
        }),
        destination: new SearchFormFieldModel(),
        directFlight: new SearchFormFieldModel({
            model: false,
        }),
        warranted: new SearchFormFieldModel({
            model: false,
        }),
        isExclusive: new SearchFormFieldModel({
            model: false,
        }),
    });
};